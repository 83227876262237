import HomeHeroArrow from "./home_hero_arrow.json";
import Certificate from "./certificate.json";
import NotFound from "./not-found.json";

const Animation = {
  HomeHeroArrow,
  Certificate,
  NotFound,
};

export default Animation;
