"use client";

import React, { useEffect } from "react";
import lottie from "lottie-web";
import Image from "next/image";

import BGAnimation from "./bgAnimation";
import styles from "./hero.module.scss";
import Animation from "@/assets/json";

function Feature(props) {
  const { title, className } = props;

  return <h3 className={`${styles.feature} ${className}`}>{title}</h3>;
}

function RightSection(props) {
  const { className } = props;

  return (
    <aside className={`${styles["right"]} ${className}`}>
      <Feature title={"Build Projects📈"} className={styles["feature1"]} />
      <Feature title={"Free Certificate 🎓"} className={styles["feature2"]} />
      <span className={styles.image}>
        <Image
          src={"/images/home-hero.webp"}
          alt="Hero-Side-Image"
          width={1200}
          height={1200}
        />
      </span>
      <Feature title={"No Sign-up needed ✅"} className={styles["feature3"]} />
      <Feature
        title={"Personalized Learning Experience 👩🏻‍💻"}
        className={styles["feature4"]}
      />
    </aside>
  );
}

function Mentors() {
  return (
    <div className={styles["info-area"]}>
      <div className={styles["images"]}>
        <div className={`${styles["image"]} ${styles["image1"]}`}>
          <Image
            src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Adhiraj%20Chauhan_ziiahQv6s.webp?updatedAt=1728465895036"
            alt="Adhiraj - Coding Mentor"
            width={50}
            height={50}
          />
        </div>
        <div className={`${styles["image"]} ${styles["image2"]}`}>
          <Image
            src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Ayushi_VatoKa9wB.webp?updatedAt=1728465897259"
            alt="Ayushi - Coding Mentor"
            width={50}
            height={50}
          />
        </div>
        <div className={`${styles["image"]} ${styles["image2"]}`}>
          <Image
            src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Satyam%20Bhalla_Iygph71tm.webp?updatedAt=1728465902951"
            alt="Satyam - Coding Mentor"
            width={50}
            height={50}
          />
        </div>
        <div className={`${styles["image"]} ${styles["image2"]}`}>
          <Image
            src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Shantanu%20S_Na5zbz16x.webp?updatedAt=1728465903138"
            alt="Shantanu - Coding Mentor"
            width={50}
            height={50}
          />
        </div>
        <div className={`${styles["image"]} ${styles["image3"]}`}>
          <Image
            src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Harshit_wjelhWZ_4.webp?updatedAt=1728465897845"
            alt="Harshit - Coding Mentor"
            width={50}
            height={50}
          />
        </div>
      </div>
      <p className={styles["texts"]}>
        100+ <br className={styles["mobile-hide"]} />
        Mentors
      </p>
    </div>
  );
}

function Hero() {
  useEffect(() => {
    let animation, arrowElement;
    const loadAnimation = () => {
      arrowElement = document.querySelector("#arrow");
      if (arrowElement) {
        animation = lottie.loadAnimation({
          container: arrowElement,
          renderer: "svg",
          loop: true,
          autoplay: true,
          animationData: Animation.HomeHeroArrow,
        });
      }
    };

    const timeoutId = setTimeout(loadAnimation, 0);

    return () => {
      clearTimeout(timeoutId);
      if (arrowElement) arrowElement.remove();
      if (animation) animation.destroy();
    };
  }, []);

  return (
    <section className={styles["container"]} id="home">
      <BGAnimation className={styles["bg-animation"]} />
      <div className={styles["gradient"]}></div>
      <main>
        <aside className={styles["left"]}>
          <h1>
            Learn & <span>Grow</span> <br />
            Together for FREE!
          </h1>

          <p className={styles.subtext}>
            The ultimate destination for Students and Content Creators who crave
            an exhilarating online learning experience.
          </p>
          <RightSection className={styles["mobile-show"]} />
          <Feature
            title={"50+ Free Programs with Certification ✅"}
            className={`${styles.highlight} ${styles.orangeborder}`}
          />
          <Mentors />
        </aside>
        <RightSection className={styles["mobile-hide"]} />
      </main>
      <div className={styles["scroll-down"]}>
        <div id="arrow" />
      </div>
    </section>
  );
}

export default Hero;
