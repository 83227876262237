"use client";

import { useDispatch } from "react-redux";
import Image from "next/image";
import React from "react";

import styles from "./creator.module.scss";
import services from "@/store/services";
import Components from "@/components";

const Creator = () => {
  const dispatch = useDispatch();

  const toggleCreatorReqModal = () => dispatch(services.toggleCreatorModal());

  return (
    <section className={styles["container"]}>
      <h2>
        Become a <span>Content Creator</span> at CipherSchools
      </h2>
      <main>
        <aside className={styles["left"]}>
          <div className={styles["points"]}>
            <h3>✅ Showcase your Expertise </h3>
            <h3>✅ Recognition and Visibility</h3>
            <h3>✅ Feedback and Growth </h3>
            <h3>✅ Professional Development</h3>
            <h3>✅ Networking Opportunities</h3>
            <h3>✅ Stay Updated with Industry Trends</h3>
          </div>

          <Components.Button
            handleClick={toggleCreatorReqModal}
            name="Get Started"
          >
            {" "}
            Get Started{" "}
          </Components.Button>
        </aside>
        <aside className={styles["right"]}>
          <div className={styles["creators-img"]}>
            <div className={styles["creator-1"]}>
              <Image
                src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Anubhav_AXAvOB2itm.webp?updatedAt=1728465895055"
                alt="Anubhav"
                width={130}
                height={130}
              />
            </div>
            <div className={styles["creator-2"]}>
              <Image
                src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Adhiraj%20Chauhan_ziiahQv6s.webp?updatedAt=1728465895036"
                alt="Adhiraj"
                width={130}
                height={130}
              />
            </div>
            <div className={styles["bgimage"]}>
              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/creator-bg_EZ-ORYUBc.webp?updatedAt=1728481206270"
                alt="creator-bg-image"
                width={800}
                height={800}
              />
            </div>
            <div className={styles["creator-3"]}>
              <Image
                src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Ayushi_VatoKa9wB.webp?updatedAt=1728465897259"
                alt="Ayushi"
                width={130}
                height={130}
              />
            </div>
            <div className={styles["creator-4"]}>
              <Image
                src="https://ik.imagekit.io/cipherschools/CipherSchools%20Mentor%20Webp/Mohit%20Sharma_PYwH0Wgyp.webp?updatedAt=1728465897837"
                alt="Mohit Sharma"
                width={130}
                height={130}
              />
            </div>
          </div>
        </aside>
      </main>
    </section>
  );
};

export default Components.Wrapper(Creator, "", "", styles["creator-container"]);
