"use client";

import React, { useEffect, useState } from "react";

import styles from "./feedback-card.module.scss";
import Image from "next/image";

const TestimonialCard = (props) => {
  const { item, readMore } = props;
  const [feedbackLength, setFeedbackLength] = useState(100);

  useEffect(() => {
    function updateFeedbackLength() {
      setFeedbackLength(window.innerWidth < 641 ? 130 : 100);
    }

    updateFeedbackLength();
    window.addEventListener("resize", updateFeedbackLength);

    return () => window.removeEventListener("resize", updateFeedbackLength);
  }, []);

  return (
    <summary className={styles["container"]}>
      <Image
        width={200}
        height={20}
        alt={`${item?.name}-profile`}
        src={item?.icon}
      />
      <figcaption className={styles["content"]}>
        <p>
          {item?.feedback?.slice(0, feedbackLength)}
          {item?.feedback?.length > feedbackLength && (
            <button
              onClick={() => readMore(item)}
              className={styles["readMoreBtn"]}
            >
              ...Read More
            </button>
          )}
        </p>
        <div className={styles["alumni-info"]}>
          <Image width={40} height={40} alt={item?.name} src={item?.image} />
          <div className={styles["alumni"]}>
            <h3>{item?.name}</h3>
            <p>{item?.role}</p>
          </div>
        </div>
      </figcaption>
    </summary>
  );
};

export default React.memo(TestimonialCard);
