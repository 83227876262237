import React from "react";

const Mission_card_data = [
  {
    title: <>Lack of motivation 😥</>,
    description: (
      <>
        Staying engaged with <span> coding can be tough,</span> especially when
        tackling challenging concepts.
      </>
    ),
  },
  {
    title: <>Career clarity ❌</>,
    description: (
      <>
        Many students feel uncertain about which area of programming to focus
        on, <span> leading to confusion and stalled progress.</span>
      </>
    ),
  },
  {
    title: <>Always in doubt 🤷🏻‍♀️ </>,
    description: (
      <>
        Students frequently wonder if they’re good enough or if they’ll ever
        master the skills <span>needed in programming.</span>
      </>
    ),
  },
  {
    title: <>Procrastination 🫨</>,
    description: (
      <>
        Putting off assignments or projects creates unnecessary stress and
        <span> impacts the learning journey.</span>
      </>
    ),
  },
];

export default Mission_card_data;
