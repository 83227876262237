import React from "react";

import styles from "./missionAccordian.module.scss";
import Tooltip from "@/components/atoms/tooltip";

const AccordionHeader = ({
  title,
  index,
  currentOpen,
  handleAccordionChange,
}) => {
  return (
    <article
      className={`${styles["accordion-title"]} ${currentOpen === index ? styles["open"] : ""}`}
      onClick={() => handleAccordionChange(index)}
    >
      <Tooltip title={title} placement="top">
        <h4>{title}</h4>
      </Tooltip>
    </article>
  );
};

export default AccordionHeader;
