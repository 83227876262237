import React, { useEffect, useState } from "react";
import styles from "./bgAnimation.module.scss";
import anime from "animejs";

const BGAnimation = (props) => {
  const { className } = props;
  const cubeSize = 100;

  //NOTE - This is to prevent the loading state
  const [load, setLoad] = useState(false);

  const [gridDimensions, setGridDimensions] = useState({
    columns: 12,
    rows: 11,
  });

  // Debounce function to limit resize events
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(this, args), delay);
    };
  };

  useEffect(() => {
    const updateGridDimensions = () => {
      if (window.innerWidth < 1100) {
        setGridDimensions({ columns: 9, rows: 12 });
      } else if (window.innerWidth < 1200) {
        setGridDimensions({ columns: 10, rows: 13 });
      } else if (window.innerWidth < 1281) {
        setGridDimensions({ columns: 11, rows: 13 });
      } else if (window.innerWidth < 1600) {
        setGridDimensions({ columns: 12, rows: 11 });
      } else {
        setGridDimensions({ columns: 13, rows: 13 });
      }
    };

    const debouncedUpdate = debounce(updateGridDimensions, 100);
    window.addEventListener("resize", debouncedUpdate);

    return () => {
      window.removeEventListener("resize", debouncedUpdate);
    };
  }, []);

  useEffect(() => {
    const pathEls = document.getElementsByClassName("animation");

    for (let i = 0; i < pathEls.length; i++) {
      const pathEl = pathEls[i];
      const offset = anime.setDashoffset(pathEl);
      pathEl.setAttribute("stroke-dashoffset", offset);
      anime({
        targets: pathEl,
        strokeDashoffset: [offset, 0],
        duration: anime.random(5000, 8000),
        delay: anime.random(0, 3000),
        loop: true,
        direction: "alternate",
        easing: "easeInOutSine",
        autoplay: true,
      });
    }
  }, [gridDimensions]);

  const generatePaths = () => {
    const paths = [];
    for (let row = 0; row < gridDimensions.rows; row++) {
      for (let col = 0; col < gridDimensions.columns; col++) {
        const x = col * cubeSize;
        const y = row * cubeSize;
        paths.push(
          <path
            className="animation"
            key={`${row}-${col}`}
            d={`M${x},${y} L${x + cubeSize},${y} L${x + cubeSize},${y + cubeSize} L${x},${
              y + cubeSize
            } Z`}
          />
        );
      }
    }
    return paths;
  };

  useEffect(() => {
    setLoad(true);
  }, []);

  if (!load) return null;

  return (
    <div className={`${styles["anim"]} ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={gridDimensions.columns * cubeSize}
        height={gridDimensions.rows * cubeSize}
        viewBox={`0 0 ${gridDimensions.columns * cubeSize} ${gridDimensions.rows * cubeSize}`}
      >
        <defs>
          <linearGradient id="strokeGradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop
              offset="0%"
              style={{ stopColor: "#999999", stopOpacity: 1 }}
            />
            <stop
              offset="100%"
              style={{ stopColor: "#999999", stopOpacity: 1 }}
            />
          </linearGradient>
        </defs>
        <g
          id="cube-grid"
          stroke="url(#strokeGradient)"
          fill="none"
          strokeWidth="1"
        >
          {generatePaths()}
        </g>
      </svg>
    </div>
  );
};

export default BGAnimation;
