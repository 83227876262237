"use client";

import { Icon } from "@iconify/react";
import Image from "next/image";
import React from "react";

import Wrapper from "@/components/shared/wrapper";
import styles from "./joinus.module.scss";

function RightSection(props) {
  const { className } = props;
  return (
    <aside className={`${styles["right"]}  ${className}`}>
      <Image
        src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/JoinUs-img_UB4fI1Mo_h.webp?updatedAt=1728368333792"
        alt="get-the-app-image"
        width={500}
        height={500}
      />
    </aside>
  );
}

function JoinUs() {
  return (
    <section className={styles["container"]}>
      <aside className={styles["left"]}>
        <h2>
          Your Gateway to
          <br /> Knowledge and Skills
        </h2>
        <p>
          <span>Join our community of learners</span> and unlock your potential
          with our engaging apps. From coding to personal development, we offer
          a wide range of courses and resources tailored to your needs.
        </p>

        <RightSection className={styles["mobile-show"]} />

        <p>Get the App -&gt;</p>

        <div className={styles["appsContainer"]}>
          <a
            href="https://apps.apple.com/in/developer/cipherschools/id1713293647"
            target="blank"
          >
            <Icon icon="ic:baseline-apple" className={styles.downloadIcon} />
            <p>
              {" "}
              <span>Download on the </span> <br />
              App Store
            </p>
          </a>

          <a
            href="https://play.google.com/store/apps/dev?id=6109068113970377181"
            target="blank"
          >
            <Icon
              icon="logos:google-play-icon"
              className={styles.downloadIcon}
            />
            <p>
              {" "}
              <span>Get it on </span> <br /> Google Play
            </p>
          </a>
        </div>
      </aside>

      <RightSection className={styles["mobile-hide"]} />
    </section>
  );
}

export default Wrapper(JoinUs, "", "", styles["joinus-container"]);
