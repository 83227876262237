"use client";

import { Autoplay, FreeMode, Pagination } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { useState } from "react";
import "swiper/scss/free-mode";
import "swiper/scss/pagination";
import "swiper/scss";

import FeedCard from "../../partials/student-card";
import styles from "./stud-feedback.module.scss";
import useGridCard from "@/hooks/useGridCard";
import FeedbackData from "@/data/Feedbacks";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";

const StudentFeedback = () => {
  const { reviewModal } = useSelector((state) => state.modal);
  const [selected, setSelected] = useState(null);
  const { vidPerView, gap } = useGridCard(480);
  const dispatch = useDispatch();

  const initialTheme = utils.getFromLocalStorage("theme") || "light";

  const toggleReviewModal = () => dispatch(services.toggleReviewModal());

  return (
    <>
      <section className={styles["container"]} id="reviews">
        <h2>What Our Students Say</h2>
        <Swiper
          slidesPerView={vidPerView}
          spaceBetween={gap}
          freeMode={true}
          pagination={{
            clickable: true,
            enabled: true,
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
          speed={5000}
          modules={[FreeMode, Autoplay, Pagination]}
          className={styles["feedcards"]}
          data-page="landing"
        >
          {FeedbackData.map((item, index) => (
            <SwiperSlide key={`feedback-${index}`}>
              <FeedCard
                item={item}
                readMore={(item) => {
                  setSelected(item);
                  toggleReviewModal();
                }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </section>
      <Components.Modal
        block={true}
        placement="center"
        heading={
          <div className={styles.header}>
            <Components.Avatar
              src={selected?.image}
              loader={"md"}
              errStyle={{ fontSize: "1rem" }}
              alt={`${selected?.name}-alumni`}
            />
            <div className={styles["alumni"]}>
              <h3>{selected?.name}</h3>
            </div>
          </div>
        }
        className={"home-page-modal"}
        showModal={reviewModal}
        setShowModal={() => {
          setSelected(null);
          toggleReviewModal();
        }}
      >
        {selected !== null ? (
          <section className={styles["modalContent"]}>
            <div className={styles["content"]}>
              <p>{selected?.comment}</p>
            </div>
          </section>
        ) : null}
      </Components.Modal>
    </>
  );
};

export default Components.Wrapper(
  StudentFeedback,
  "",
  "",
  styles["studfeed-card"]
);
