import styles from "./missionCard.module.scss";

const MissionCard = (props) => {
  const { title, description } = props;
  return (
    <summary className={styles["card-container"]}>
      <h3 className={styles["card-title"]}>{title}</h3>
      <p className={styles["card-desc"]}>{description}</p>
    </summary>
  );
};

export default MissionCard;
