import React from "react";
import Image from "next/image";

import styles from "./mentorCard.module.scss";

const MentorCard = ({ name, role, image, icon }) => {
  return (
    <summary className={styles["container"]}>
      <figure className={styles["images"]}>
        <div className={styles["profile-img"]}>
          <Image alt={`${name} `} src={image} width={170} height={170} />
        </div>
        <div className={styles["company-img"]}>
          <Image alt={`${name} `} src={icon} width={55} height={55} />
        </div>
      </figure>
      <figcaption className={styles["content"]}>
        <h3 className={styles["name"]}>{name}</h3>
        <p className={styles["role"]}>{role}</p>
      </figcaption>
    </summary>
  );
};

export default MentorCard;
