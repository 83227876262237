"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";

import AccordionHeader from "../../partials/mission-accordian";
import AccordianData from "@/data/LandingAccordianData";
import Mission_card_data from "@/data/MissionCardData";
import MissionCard from "../../partials/mission-card";
import Wrapper from "@/components/shared/wrapper";
import styles from "./mission.module.scss";

function Mission() {
  const [currentOpen, setCurrentOpen] = useState(0);
  // const [load, setLoad] = useState(false);

  // //NOTE - This is to prevent the loading state
  // useEffect(() => {
  //   setLoad(true);
  // }, []);

  // if (!load) return null;

  const handleAccordionChange = (index) => {
    setCurrentOpen((prev) => (prev === index ? null : index));
  };

  return (
    <section className={styles["container"]}>
      <main>
        <header>
          <h2>
            Why Did We Start <span>CipherSchools?</span>
          </h2>
          <p>
            Here are some key challenges predominantly experienced by the
            students.
          </p>
        </header>

        <div className={styles["cards"]}>
          {Mission_card_data.map((item, index) => (
            <MissionCard
              key={index}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>

        <div className={styles["inner-container"]}>
          <aside className={styles["left"]}>
            <figure className={styles["mobile-hide"]}>
              <Image
                src="/images/Why_cipherschools-mockup.webp"
                alt="CipherSchools-platform-mockup"
                width={700}
                height={700}
              />
            </figure>
            {/* {isShow && <AnimationMission className={styles["mobile-hide"]} />} */}
          </aside>
          <aside className={styles["right"]}>
            <h3 className={styles["title"]}>
              Here's <span>how we solve</span> all{" "}
              <br className={styles["break-line"]} />
              your problems
            </h3>
            <figure className={styles["mobile-show"]}>
              <Image
                src="/images/Why_cipherschools-mockup.webp"
                alt="CipherSchools-platform-mockup"
                width={800}
                height={800}
              />
            </figure>
            {/* {!isShow && <AnimationMission className={styles["mobile-show"]} />} */}

            <div className={styles["accordian"]}>
              {AccordianData.map((item, index) => (
                <div key={index} className={styles["accordion-item"]}>
                  <AccordionHeader
                    title={item.title}
                    index={index}
                    currentOpen={currentOpen}
                    handleAccordionChange={handleAccordionChange}
                  />

                  {currentOpen === index && <p>{item.description}</p>}
                </div>
              ))}
            </div>
          </aside>
        </div>
      </main>
    </section>
  );
}

export default Wrapper(Mission, "", "", styles["mission-container"]);
