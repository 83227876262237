import { useState, useEffect } from "react";

function useHomeGridCard(cardWidth) {
  const [vidPerView, setVidPerView] = useState(2);
  const [catPerView, setCatPerView] = useState(2);
  const [gap, setGap] = useState(10);

  function windowHandler() {
    // Minimum width infinite, Maximum width fixed
    if (window.innerWidth <= 310) {
      setGap(5);
      setVidPerView(1);
      setCatPerView(1);
      return;
    }
    if (window.innerWidth <= 460) {
      setGap(10);
      setVidPerView(1.5);
      setCatPerView(1.5);
      return;
    }
    if (window.innerWidth <= 641) {
      setGap(10);
      setVidPerView(2);
      setCatPerView(2);
      return;
    }
    if (window.innerWidth <= 800) {
      setVidPerView(3);
      setCatPerView(3);
      return;
    }
    // Minimum width fixed, Maximum width infinite
    if (window.innerWidth > 800) {
      setVidPerView(Math.ceil(window.innerWidth / (cardWidth || 400)));
      setCatPerView(Math.ceil(window.innerWidth / (cardWidth || 400)));
    }
    if (window.innerWidth > 1000) {
      setGap(20);
    }
  }

  useEffect(() => {
    if (typeof window === "undefined") return;
    windowHandler();
    window.addEventListener("resize", windowHandler);
    return () => window.removeEventListener("resize", windowHandler);
  }, []);

  return { vidPerView, catPerView, gap };
}

export default useHomeGridCard;
