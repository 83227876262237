"use client";

import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css";

import TestimonialCard from "../../partials/feedback-card";
import AlumniFeedbackData from "@/data/AlumniFeedbackData";
import useHomeGridCard from "@/hooks/useHomeGridCard";
import styles from "./alum-feedback.module.scss";
import services from "@/store/services";
import Components from "@/components";
import utils from "@/utils";

const AlumFeedback = () => {
  const { testimonialModal } = useSelector((state) => state.modal);
  const { vidPerView, gap } = useHomeGridCard(380);
  const [selected, setSelected] = useState(null);
  const dispatch = useDispatch();

  // //NOTE - This is to prevent the loading state swiper issue
  const initialTheme = utils.getFromLocalStorage("theme") || "light";

  const toggleTestimonialModal = () =>
    dispatch(services.toggleTestimonialModal());

  return (
    <>
      <section className={styles["container"]}>
        <header>
          <h2>We Love to Read Them</h2>
          <p>
            What our alumni say about us- <span>Alumni Success Stories</span>
          </p>
        </header>
        <main className={styles["alumni-data"]}>
          <aside className={styles["top"]}>
            <Swiper
              slidesPerView={vidPerView}
              spaceBetween={gap}
              freeMode={true}
              pagination={{
                enabled: false,
              }}
              autoplay={{
                delay: 800,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
              }}
              loop={true}
              speed={6000}
              modules={[FreeMode, Autoplay]}
              className={styles["alumni"]}
              data-class="alumnis"
              data-page="landing"
            >
              {AlumniFeedbackData[0].map((item, index) => (
                <SwiperSlide key={`mentor-${index}`}>
                  <TestimonialCard
                    item={item}
                    readMore={(item) => {
                      setSelected(item);
                      toggleTestimonialModal();
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </aside>

          <aside className={styles["bottom"]}>
            <Swiper
              slidesPerView={vidPerView}
              spaceBetween={gap}
              freeMode={true}
              pagination={{
                enabled: false,
              }}
              autoplay={{
                delay: 400,
                disableOnInteraction: false,
                pauseOnMouseEnter: false,
              }}
              loop={true}
              speed={5500}
              modules={[FreeMode, Autoplay]}
              className={styles["alumni"]}
              data-class="alumnis"
              data-page="landing"
            >
              {AlumniFeedbackData[1].map((item, index) => (
                <SwiperSlide key={`mentor-${index}`}>
                  <TestimonialCard
                    item={item}
                    readMore={(item) => {
                      setSelected(item);
                      toggleTestimonialModal();
                    }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </aside>
        </main>
      </section>

      <Components.Modal
        block={true}
        placement="center"
        heading={
          <div className={styles.image}>
            <Image
              alt={`${selected?.name}-company`}
              src={selected?.icon}
              width={200}
              height={100}
            />
          </div>
        }
        className={"home-page-modal"}
        showModal={testimonialModal}
        setShowModal={() => {
          setSelected(null);
          toggleTestimonialModal();
        }}
      >
        {selected !== null ? (
          <section className={styles["modalContent"]}>
            <figcaption className={styles["content"]}>
              <p>{selected?.feedback}</p>
              <div className={styles["alumni-info"]}>
                <Image
                  width={100}
                  height={100}
                  src={selected?.image}
                  alt={`${selected.name}-profile`}
                />
                <div className={styles["alumni"]}>
                  <h3>{selected?.name}</h3>
                  <p>{selected?.role}</p>
                </div>
              </div>
            </figcaption>
          </section>
        ) : null}
      </Components.Modal>
    </>
  );
};

export default AlumFeedback;
