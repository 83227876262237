"use client";

import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, FreeMode } from "swiper";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css";

import MentorCard from "../../partials/mentor-card";
import MentorData from "@/data/Mentors.json";
import styles from "./mentor.module.scss";
import utils from "@/utils";

const Mentor = () => {
  const [perView, setPerView] = useState(4);
  const [gap, setGap] = useState(50);

  const initialTheme = utils.getFromLocalStorage("theme") || "light";

  function windowHandler() {
    if (window.innerWidth > 1300) {
      setPerView(7);
      setGap(50);
    }
    if (window.innerWidth <= 1300) {
      setPerView(6);
      setGap(30);
    }
    if (window.innerWidth <= 1150) {
      setPerView(5);
      setGap(25);
    }
    if (window.innerWidth <= 900) {
      setPerView(4);
      setGap(20);
    }
    if (window.innerWidth <= 520) {
      setPerView(3);
      setGap(15);
    }
    if (window.innerWidth <= 400) {
      setPerView(2.5);
      setGap(10);
    }
  }

  useEffect(() => {
    if (typeof window === "undefined") return undefined;
    windowHandler();
    window.addEventListener("resize", windowHandler);
    return () => window.removeEventListener("resize", windowHandler);
  }, []);

  return (
    <section className={styles["container"]}>
      <h2>Meet Our Expert Mentors</h2>

      <div className={styles["mentor-container"]}>
        <Swiper
          slidesPerView={perView}
          spaceBetween={gap}
          freeMode={true}
          pagination={{
            enabled: false,
          }}
          autoplay={{
            delay: 300,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
          loop={true}
          speed={8000}
          modules={[FreeMode, Autoplay]}
          className={styles["mentors"]}
          data-class="mentors"
          data-page="landing"
        >
          {MentorData[0].map((item, index) => (
            <SwiperSlide key={`mentor-${index}`}>
              <MentorCard
                image={item.image}
                icon={item.icon}
                name={item.name}
                role={item.role}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        <Swiper
          slidesPerView={perView}
          spaceBetween={gap}
          freeMode={true}
          pagination={{
            enabled: false,
          }}
          autoplay={{
            delay: 900,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
          }}
          loop={true}
          speed={8000}
          modules={[FreeMode, Autoplay]}
          className={styles["mentors"]}
          data-class="mentors"
          data-page="landing"
        >
          {MentorData[1].map((item, index) => (
            <SwiperSlide key={`mentor-${index}`}>
              <MentorCard
                image={item.image}
                icon={item.icon}
                name={item.name}
                role={item.role}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Mentor;
