import React from "react";

import styles from "./student-card.module.scss";
import Components from "@/components";

function FeedCard(props) {
  const { item, readMore } = props;

  return (
    <summary className={styles["card"]}>
      <p>
        {item?.comment.slice(0, 120)}
        {item?.comment.length > 120 && (
          <button
            onClick={() => readMore(item)}
            className={styles["readMoreBtn"]}
          >
            ...Read More
          </button>
        )}
      </p>

      <div className={styles["details"]}>
        <Components.Avatar
          src={item?.image}
          alt={item?.name}
          loader={"sm"}
          errStyle={{ fontSize: "1rem" }}
        />
        <p>
          {item?.name} <br />
          <span>{item?.course}</span>
        </p>
      </div>
    </summary>
  );
}

export default React.memo(FeedCard);
