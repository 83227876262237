"use client";

import { useSelector, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { useRouter } from "next/navigation";
import Image from "next/image";
import Link from "next/link";

import Wrapper from "@/components/shared/wrapper";
import styles from "./explore.module.scss";
import services from "@/store/services";
import utils from "@/utils";
import api from "@/api";

const Explore = () => {
  const user = useSelector((state) => state.user.users);
  const enrollments = useSelector((state) => state?.enrollment);
  const [courseDetails, setCourseDetails] = useState(null);
  const dispatch = useDispatch();
  const router = useRouter();

  useEffect(() => {
    const fetchCourseDetails = async () => {
      if (enrollments.length > 0) {
        const firstCourseId = enrollments[0]?.courseId;

        try {
          const response = await api.getvideolink(firstCourseId);
          if (response.data.success === 200) {
            const { seoTitle, firstVideoTitle } = response.data.result;
            setCourseDetails({ seoTitle, firstVideoTitle });
          }
        } catch (error) {
          console.error("Error fetching course details:", error);
        }
      }
    };

    fetchCourseDetails();
  }, [enrollments]);

  const handleKnowMoreClick = () => {
    if (!user?.email) {
      dispatch(services.toggleSigninModal());
    } else {
      router.push(`/profile/${user?.username}`);
    }
  };

  const handleKnowMoreClickNotes = (e) => {
    e.preventDefault();
    dispatch(services.startNotesTour());
    router.push(
      `/courses/fullstack-development-using-mernmongo-db-expressjs-reactjs-nodejs-beginner-friendly-3e8e/react-ii-class-21-3ea5`
    );
  };

  return (
    <section className={styles["container"]}>
      <h2>
        A Lot to Explore and <span>Grab</span>
      </h2>
      <p>
        Get <span>more than just free courses! </span> Explore our additional
        features to help you grow your career.
      </p>

      <main className={styles["main"]}>
        <aside className={styles["left"]}>
          <article>
            <div className={styles["template-1"]}>
              <div className={styles.plutonnHeading}>
                <h3>Create your Free Resume ✅</h3>
              </div>
              <p>
                Get a chance to land your dream job by creating an{" "}
                <span> ATS-friendly resume for free. </span>{" "}
              </p>
              <Link href="/resume-builder">Know more -&gt;</Link>
              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/explore1_Sg8wAA6DS6.webp?updatedAt=1728632352053"
                alt="Resume-template"
                width={500}
                height={500}
              />
            </div>
          </article>

          <article>
            <div className={styles["template-2"]}>
              <div className={styles.plutonnHeading}>
                <h3>Share your Portfolio 🌐 👍🏻</h3>
              </div>
              <p>
                <span>Share your public profile as your own portfolio</span>{" "}
                page and showcase your achievements to others.
              </p>

              <div className={styles.link} onClick={handleKnowMoreClick}>
                Know more -&gt;
              </div>

              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/Group%2037778_rygbAaOyC.webp?updatedAt=1728632352035"
                alt="Public-profile-template"
                width={500}
                height={500}
              />
            </div>
          </article>

          <article>
            <div className={styles["template-3"]}>
              <div className={styles.plutonnHeading}>
                <h3>Get Smart, Get Rewards 🎁️</h3>
              </div>
              <p>
                <span className={styles["cipherpoints-bold"]}>
                  Learn, collect CipherPoints
                </span>
                , and unlock awesome goodies.{" "}
                <span>Turn your learning time into reward time.</span>
              </p>

              <Link href="/redeem/rewards#updated-policy">Know more -&gt;</Link>
              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/Group%2037719_voAwwd8l9U.webp?updatedAt=1728632352030"
                alt="CipherPoints-Policy-Template"
                width={500}
                height={500}
              />
            </div>
          </article>
        </aside>

        <aside className={styles["right"]}>
          <article>
            <div className={styles["template-4"]}>
              <div className={styles.plutonnHeading}>
                <h3>Track your Learning Progress 📈 </h3>
              </div>
              <p>
                <span> Plan and track your learning </span>progress by keeping
                track of your programming skills and development.
              </p>

              <Link
                href={user?.email ? "/profile/me" : "#"}
                onClick={handleKnowMoreClick}
              >
                Know more -&gt;
              </Link>

              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/Group%2037595_6IBIRDl_W.webp?updatedAt=1728632352036"
                alt="Progress-tracking-template"
                width={500}
                height={500}
              />
            </div>
          </article>

          <article>
            <div className={styles["template-5"]}>
              <div className={styles.plutonnHeading}>
                <h3>Join the Community today! </h3>
                <Image
                  width={500}
                  height={500}
                  alt={"Community-image"}
                  src="https://ik.imagekit.io/cipherschools/New-HomePage-cs.cipher/home_community_ASccEZTF_.png?updatedAt=1734937921560"
                />
              </div>
              <p>
                Where learning meets fun. <br />
                <span>Discover, Connect, and Share Knowledge</span> with friends
                at <span className={styles.plutonn}> Plutonn. </span>
              </p>
              <Link href="https://www.plutonn.com/" target="_blank">
                Know more -&gt;
              </Link>
              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/1%20image_AwOfEdr3a.webp?updatedAt=1728632352108"
                alt="Community-template"
                width={500}
                height={500}
              />
            </div>
          </article>

          <article>
            <div className={styles["template-6"]}>
              <div className={styles.plutonnHeading}>
                <h3>Take Effective Notes ✍🏻 </h3>
              </div>
              <p>
                <span>
                  Enhance your learning experience with the Notes feature.
                </span>{" "}
                Your personalized study companion, aiding understanding and
                retention.
              </p>
              <div className={styles.link} onClick={handleKnowMoreClickNotes}>
                <p>Know more -&gt;</p>
              </div>

              <Image
                src="https://ik.imagekit.io/cipherschools/LandingPageNew_images/301shots_so%201_K29wZQZ65O.webp?updatedAt=1728632352122"
                alt="Take-Notes-template"
                width={500}
                height={500}
              />
            </div>
          </article>
        </aside>
      </main>
      <a href="/courses" className={styles.btn}>
        Start Learning Now -&gt;
      </a>
    </section>
  );
};

export default Wrapper(Explore, "", "", styles["explore-container"]);
